<template>
  <div class="show-inbox">
    <!-- Row Header -->
    <v-row>
      <v-col cols="12" md="6" sm="12" class="">
        <div class="header-inbox-all" id="tabs">
          <router-link :to="'/inbox'">
            <p
              class="main-color title value"
              style="cursor: pointer; display: inline; margin-right: 7px"
            >
              {{ $t("All Inbox") }}
            </p></router-link
          >

          <p class="title value" style="display: inline">{{ subject }}</p>
        </div>
      </v-col>
      <!-- aicons actions -->
      <v-col cols="12" md="6" sm="12">
        <div class="center tex-center-xs">
          <v-icon
            v-if="arcived"
            class="main-color"
            style="cursor: pointer"
            @click="messageChangStatus('arcived', 0)"
            title="Unarchived"
            >archive</v-icon
          >

          <v-icon
            v-else
            @click="messageChangStatus('arcived', 1)"
            style="cursor: pointer; opacity: 0.6"
            title="Arcived"
            >archive</v-icon
          >
          <v-icon
            v-if="favourite"
            style="cursor: pointer; color: #f4b212"
            title="Unfavourite"
            @click="messageChangStatus('favourite', 0)"
            >star</v-icon
          >
          <v-icon
            v-else
            @click="messageChangStatus('favourite', 1)"
            style="cursor: pointer"
            title="Favourite"
            >star_outline</v-icon
          >

          <v-icon
            @click="reply"
            style="opacity: 0.6; cursor: pointer"
            title="Reply"
            >reply_all</v-icon
          >
        </div>
      </v-col>
    </v-row>

    <!-- Row message -->
    <v-row v-for="(item, index) in items" :key="index">
      <!-- message -->
      <v-col cols="8" md="8" sm="8">
        <v-card
          class="text-left"
          style="box-shadow: none; background: transparent"
        >
          <v-list-item five-line>
            <v-row>
              <v-col cols="12" md="2" sm="5" class="text-right col-image-user"
                ><v-list-item-avatar
                  tile
                  :class="
                    item.is_block
                      ? 'avatar-rounder user-image blocked'
                      : 'avatar-rounder user-image'
                  "
                  size="60"
                  ><img
                    v-if="item.user_logo != null"
                    :src="item.user_logo"
                    alt="John"
                  />
                  <img
                    v-else
                    class="rounded-circle"
                    src="../../assets/avatar.png"
                  />
                </v-list-item-avatar>

                <div
                  class="block"
                  v-if="!item.is_block && currentuser != item.user_id"
                  @click="block(item.user_id)"
                >
                  <v-icon style="opacity: 0.6; cursor: pointer">block</v-icon
                  >{{ $t("Block") }}
                </div>

                <div
                  class="block"
                  v-if="item.is_block && currentuser != item.user_id"
                  @click="UnBlock(item.user_id)"
                >
                  <v-icon style="opacity: 0.6; cursor: pointer">block</v-icon
                  >{{ $t("Unblock") }}
                </div>
              </v-col>

              <v-col cols="12" md="10" sm="7" style="padding-left: 0">
                <div>
                  <div class="bold">
                    {{ item.created_by }}
                  </div>
                  <div class="text-center ss">
                    <v-chip
                      class="ma-2"
                      v-for="(to, index) in item.to"
                      :key="index"
                      color="#8ba7f5"
                      style="color: #f7f7f7"
                    >
                      {{ to }}
                    </v-chip>
                  </div>
                  <hr />
                  <div v-html="item.message"></div>
                  <hr />
                </div>
                <!-- <v-list-item-content class="text-left">
                  <div>
                    <v-list-item-title
                      style="margin-bottom: 0"
                      class="mb-2 gray"
                    >
                      <div class="text-center ss">
                        <v-chip
                          class="ma-2"
                          v-for="(to, index) in item.to"
                          :key="index"
                        >
                          {{ to }}
                        </v-chip>
                      </div>
                    </v-list-item-title>
                  </div>
                  <v-list-item-subtitle>
                    <div v-html="item.message"></div>
                  </v-list-item-subtitle>
                </v-list-item-content> -->

                <div class="attachments">
                  <v-row class="images" v-if="item.attachments.image">
                    <v-col
                      cols="6"
                      sm="3"
                      v-for="(image, index) in item.attachments.image"
                      :key="index"
                    >
                      <a :href="image.url" target="_blank" class="imgContainer">
                        <img :src="image.url" class="postImg" />
                      </a>
                    </v-col>
                  </v-row>
                  <v-row class="other">
                    <p style="width: 100%"></p>
                    <div class="video" v-if="item.attachments.video.length > 0">
                      <v-icon class="main-color">movie</v-icon>
                      <a
                        :href="file.url"
                        target="_blank"
                        v-for="(file, index) in item.attachments.video"
                        :key="index"
                        >{{ file.name }}</a
                      >
                    </div>
                    <div
                      class="document"
                      v-if="item.attachments.document.length > 0"
                    >
                      <span
                        v-for="(file, index) in item.attachments.document"
                        :key="index"
                      >
                        <v-icon class="main-color">description</v-icon>
                        <a :href="file.url" target="_blank">{{ file.name }}</a>
                      </span>
                    </div>
                    <div class="audio" v-if="item.attachments.audio.length > 0">
                      <v-icon class="main-color">audiotrack</v-icon>
                      <a
                        :href="file.url"
                        target="_blank"
                        v-for="(file, index) in item.attachments.audio"
                        :key="index"
                        >{{ file.name }}</a
                      >
                    </div>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-list-item>
        </v-card>
        <br />
      </v-col>
      <!-- date -->
      <v-col cols="4" md="4" sm="4">
        <p class="text-center date-time">{{ item.date }}</p>
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <!-- row Reply Form -->
    <v-row v-if="openReplay"
      ><div class="edit">
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="12" md="12" class="info-list">
              <v-row>
                <v-col cols="12" md="12">
                  <p class="value">
                    <strong class="custom-strong">To:</strong>
                    <v-autocomplete
                      multiple
                      hide-selected
                      :items="allUsers"
                      @change="hideLabel = true"
                      item-text="name"
                      item-value="id"
                      v-model="item.recivers"
                      :rules="[validationRules.required_multiple]"
                      solo
                      chips
                    ></v-autocomplete>
                  </p>

                  <div
                    v-if="validation_errors.recivers"
                    style="margin-top: -20px"
                  >
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors.recivers"
                      :key="index"
                      @focus="onFocus()"
                    >
                      {{ error }}
                    </p>
                  </div>
                </v-col>

                <v-col cols="12" md="12">
                  <p class="value">
                    <strong class="custom-strong">{{ $t("Message") }}:</strong>

                    <vue-editor
                      v-model.trim="item.body"
                      :editorToolbar="customToolbar"
                    ></vue-editor>
                  </p>
                  <p class="red--text" v-if="validationEditor">
                    {{ $t("This field is required") }}
                  </p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-divider></v-divider>
          <v-row>
            <v-col cols="12" md="12">
              <div class="attach-files">
                <div class="filesname" v-if="fileNames != ''">
                  <p class="files">{{ fileNames }}</p>
                  <div class="clear">
                    <v-icon
                      title="Clear Attachments"
                      class=""
                      @click="clearAttach()"
                      >close</v-icon
                    >
                  </div>
                </div>

                <v-file-input
                  class=""
                  id="myFileBrowser"
                  style="display: none !important"
                  multiple
                  ref="attachment"
                  @change="readFiles"
                ></v-file-input>

                <v-btn icon @click="openFileInput()">
                  <v-icon class="main-color" style="cursor: pointer"
                    >attach_file</v-icon
                  >
                </v-btn>
                <v-icon
                  class="main-color"
                  style="float: right; cursor: pointer"
                  @click.prevent="saveReply()"
                  >send</v-icon
                >

                <p class="red--text" v-if="validationFiles">
                  {{
                    $t("This File is Bigger Than Max File Size Allowed (50 MG)")
                  }}
                </p>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </v-form></div
    ></v-row>
    <!-- snackbar -->
    <v-snackbar
      v-model="snack"
      :timeout="snackTime"
      :color="snackColor"
      :right="true"
      :top="true"
    >
      <!-- {{ snackText }} -->

      <span class="white--text">{{ snackText }}</span>

      <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
// import ACL from "../../acl";
import { validationMixin } from "../../mixins/validationMixin";
import { VueEditor } from "vue2-editor";
export default {
  name: "inboxShow",
  mixins: [validationMixin],
  components: {
    VueEditor,
  },
  data() {
    return {
      options: {
        itemsPerPage: 100,
      },
      currentuser: localStorage.id,
      openReplay: false,
      //   attachment: [],
      fileNames: "",
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: "" }, { align: "justify" }, { align: "right" }],
        ["link"],
      ],
      validationEditor: false,
      validationFiles: false,
      snack: false,
      snackColor: "",
      snackText: "",
      snackTime: 3000,
      allUsers: [],
      sizes: [],
      items: [],
      to: [],
      arcived: "",
      favourite: "",
      masseged_created_by: "",
      subject: "",
      item: {
        recivers: [],
        body: "",
        attachments: [],
      },

      valid: true,
      validation_errors: [],
    };
  },

  methods: {
    reply() {
      this.openReplay = true;

      this.to.forEach((element) => this.item.recivers.push(element));

      $("html, body").animate({ scrollTop: $(document).height() }, "slow");
    },
    UnBlock(id) {
      // console.log(id);
      axios
        .get(this.getApiUrl + "/inbox/unblockUser/" + id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((res) => {
          if (res.data.status.error == false) {
            console.log(res.data.data);
            this.getMessage();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    block(id) {
      axios
        .get(this.getApiUrl + "/inbox/blockUser/" + id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((res) => {
          if (res.data.status.error == false) {
            console.log(res.data.data);
            let blockindex = this.to.indexOf(id);
            console.log(blockindex);

            this.getMessage();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    messageChangStatus(status, value) {
      let data = [status, value];
      axios
        .post(
          this.getApiUrl +
            "/inbox/changeStatus/" +
            this.$router.currentRoute.params.id,
          data,

          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          console.log(response);

          this.getMessage();
        });
    },
    openFileInput() {
      document.getElementById("myFileBrowser").click();
    },
    clearAttach() {
      this.item.attachments = [];
      this.fileNames = "";
      this.validationFiles = false;
    },
    getMessage() {
      let id = this.$router.currentRoute.params.id;
      axios
        .get(this.getApiUrl + "/inbox/show/" + id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.items = response.data.data.messages;
          this.to = response.data.data.to;
          this.masseged_created_by = response.data.data.masseged_created_by;
          this.subject = response.data.data.subject;
          this.arcived = response.data.data.arcived;
          this.favourite = response.data.data.favourite;
        });
    },
    getUsers() {
      axios
        .get(this.getApiUrl + "/inbox/getAllUser", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((res) => {
          if (res.data.status.error == false) {
            this.allUsers = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    saveReply() {
      let id = this.$router.currentRoute.params.id;
      if (!this.valid) {
        this.validate();
      } else {
        if (this.item.body == "") {
          this.validationEditor = true;
        } else {
          let totalSize = 0;
          this.sizes.forEach((size) => {
            totalSize += size;
          });
          if (totalSize > 50) {
            this.validationFiles = true;

            return;
          }
          this.validationEditor = false;
          const formData = new FormData();
          if (this.item.attachments.length > 0) {
            this.item.attachments.forEach((file) => {
              formData.append("file[]", file);
            });
          }

          formData.append("body", this.item.body);
          formData.append("subject", this.item.subject);
          if (this.item.recivers.length > 0 && this.item.recivers != "") {
            this.item.recivers.forEach((reciver) => {
              formData.append("recivers[]", reciver);
            });
          }

          axios
            .post(this.getApiUrl + "/inbox/reply/" + id, formData, {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            })
            .then((response) => {
              if (response.data.status.error == true) {
                Object.assign(
                  this.validation_errors,
                  response.data.status.validation_errors
                );
              } else {
                this.$refs.form.resetValidation();
                this.item.recivers = [];
                this.item.body = "";
                this.clearAttach();
                this.openReplay = false;
                this.getMessage();
              }
            });
        }
      }
    },
    readFiles(files) {
      var _this = this;

      files.forEach((file) => {
        _this.validationFiles = false;
        let sizemg = file.size / 1000000;
        _this.sizes.push(sizemg);
        if (_this.fileNames.length == 0) {
          _this.fileNames = file.name;
        } else {
          _this.fileNames = _this.fileNames + ", " + file.name;
        }
        _this.item.attachments.push(file);
        // }
      });
    },
    onFocus() {
      this.validation_errors = [];
    },
  },
  mounted() {
    this.getMessage();
    this.getUsers();
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
.myFileBrowser {
  opacity: 0;
  position: absolute;
  left: 0;
  z-index: 999;
}
hr.basic {
  margin: 0;
}
hr.second {
  border: 2px solid $main-color;
  margin: 0 auto;
  background-color: $main-color;
}

.title-col {
  padding-bottom: 0;
}

.custom-strong {
  display: block;
  margin-bottom: 6px;
}
.text-right {
  text-align: right !important;
}
.attach-files {
  padding: 0 10px;
}
.filesname {
  background: #eee;
  padding: 5px;
  border-radius: 13px;
  overflow: hidden;
}
.files {
  display: initial;
}
.filesname .clear {
  float: right;
}
.show-xs {
  display: none;
}
.show-inbox .text-left {
  text-align: left !important;
}
.user-image:hover,
.blocked {
  opacity: 0.5;
  cursor: pointer;
}
.block {
  color: #888;
  font-size: 13px;
  cursor: pointer;
  position: absolute;
  right: -30px;
  top: 17%;
  background: #fff;
  border-radius: 7px;
  padding: 5px;
  z-index: 9999;
  display: none;
}
.col-image-user:hover .block {
  max-height: 100px;
  display: block;
}
@media screen and (max-width: 420px) {
  .title {
    display: inline;
  }
}
</style>
